.profile-div {
  display: flex;
  max-width: 1024px;
  justify-content: center;
  align-items: center;
  margin: 0 8%;
  padding: 64px 0;
}



.profileName,
.NMLS {
  color: #004e82;
  margin: 0;
}

.profileName {
  margin-top: 16px;
}

.NMLS {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 400;
}

.headProfilePic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(145, 149, 157, 0.3);
}

@media (min-width: 768px) {
  .profile-background {
    background-image: url(../images/homeBackground.png);
        -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  }

  .profileP {
    color: #575757;
  }
  
  .profile-div {
    padding: 8%;
    margin: 0 auto;
  }

  .profileName {
    margin-top: 0px;
  }

  .profile-grid {
    display: grid;
    gap: 8%;
    align-items: start;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    background: transparent;
  }

  .headProfilePic {
    padding-top: 0px;
    box-shadow: 0px 8px 16px rgba(145, 149, 157, 0.3);
  }
}

@media (min-width: 1024px) {
  .profile-grid {
    align-items: center;
  }
}
